import React, { Component, Fragment } from "react";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo } from "@fortawesome/pro-light-svg-icons";

// Styles
import styled from "styled-components";

export default class VideoButton extends Component {
  // Start the popover closed
  state = {
    url: "",
    open: false
  };

  // When the popover is open and users click anywhere on the page,
  // the popover should close
  componentDidMount() {
    document.addEventListener("click", this.closePopover);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.closePopover);
  }

  // Note: make sure whenever a click happens within the popover it is not closed
  onPopoverClick = () => {
    this.preventNextClose = true;
  };

  openPopover = () => {
    if (!this.state.open) {
      this.preventNextClose = true;
      this.setState({
        open: true
      });
    }
  };

  closePopover = () => {
    if (!this.preventNextClose && this.state.open) {
      this.setState({
        open: false
      });
    }

    this.preventNextClose = false;
  };

  addVideo = () => {
    const { editorState, onChange } = this.props;
    onChange(this.props.modifier(editorState, { src: this.state.url }));
    this.setState({ url: "", open:false });
  };

  changeUrl = evt => {
    this.setState({ url: evt.target.value });
  };

  render() {
    return (
      <Fragment>
        <VideoButtonContainer onClick={this.openPopover}>
          <FontAwesomeIcon icon={faVideo} />
        </VideoButtonContainer>

        {this.state.open && (
          <UrlContainer>
            <div onClick={this.onPopoverClick}>
              <input
                type="text"
                placeholder="Paste the video url …"
                className="addVideoInput"
                onChange={this.changeUrl}
                value={this.state.url}
              />
              <button
                className="addVideoConfirmButton"
                type="button"
                onClick={this.addVideo}
              >
                Add
              </button>
            </div>
          </UrlContainer>
        )}
      </Fragment>
    );
  }
}
const VideoButtonContainer = styled.button`
  background: #fbfbfb;
  color: #888;
  font-size: 18px;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px;
  outline: 0;
  &:hover {
    background: #f3f3f3;
    outline: 0;
  }
`;
const UrlContainer = styled.div`
  margin: 10px;

  input {
    padding: 8px;
    border-radius: 3px;
    margin-right: 15px;
    border: 1px solid lightgray;
    width: 50%;
  }
`;
