import React, { Component, Fragment } from "react";
import { url } from "../settings";

// Dependencies
import axios from "axios";

// Components
import Header from "./Navigation/Header";

// Styles
import styled from "styled-components";
import { purple, green, red, grayscale } from "../utilities";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.withCredentials = true;

class CreateCase extends Component {
  state = {
    caseCreated: false
  };

  createClinicalScenario = () => {
    axios
      .post(`${url}/api/v0.1/create-case/`, {
        caseType: "clinical scenario"
      })
      .then(response => {
        const caseId = response.data.caseId;
        const caseIdHash = response.data.caseIdHash;
        this.props.history.push(`/edit/${caseIdHash}`);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  createQA = () => {
    console.log("Create QA");

    axios
      .post(`${url}/api/v0.1/create-case/`, {
        caseType: "question"
      })
      .then(response => {
        const caseId = response.data.caseId;
        const caseIdHash = response.data.caseIdHash;
        this.props.history.push(`/edit/${caseIdHash}`);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  render() {
    return (
      <Fragment>
        <Header label={"Create Case"} />
        <CreateCaseContainer>
          <Button onClick={this.createClinicalScenario}>
            <div className="button-label">
              <h3 className="main">Create Clinical Scenario</h3>
              <p>
                Clinical scenarios are medical cases that invole more detail
                into a particular clinical pathology or diagnosis.
              </p>
            </div>
          </Button>
          <Button onClick={this.createQA}>
            <div className="button-label">
              <h3 className="main">Create Question & Answer</h3>
              <p>A simple medical question and answer</p>
            </div>
          </Button>
        </CreateCaseContainer>
      </Fragment>
    );
  }
}

export default CreateCase;

const CreateCaseContainer = styled.div`
  max-width: 500px;
  margin: auto;
  padding-top: 50px;
`;

const Button = styled.button`
  display: block;
  margin-bottom: 15px;
  padding: 25px;
  border: none;
  background-color: ${purple.darkDark};
  color: white;
  text-align: left;
  width: 100%;
  .button-label {
    font-family: futura-pt, sans-serif;
    margin-left: 15px;
    h3 {
      font-size: 22px;
      margin: 0;
    }
    p {
      font-size: 16px;
      font-family: futura-pt, sans-serif;
    }
  }
`;
