import React, { useState, useEffect, useContext } from "react";
import { url } from "../../settings";

import {
  EditorState,
  RichUtils,
  AtomicBlockUtils,
  convertToRaw,
  convertFromRaw
} from "draft-js";

// Dependencies
import { Route, Link } from "react-router-dom";
import axios from "axios";
import debounce from "lodash/debounce";

// Components
import CodeHealthEditorReadOnly from "../Editors/CodeHealthEditorReadOnly";
import CodeHealthEditorPure from "../Editors/CodeHealthEditorPure";
import CodeHealthInput from "../CodeHealthInput";

// Context
import { CaseContext } from "../../Context/CaseContext";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";

// Styles
import styled from "styled-components";
import { purple, green, red, grayscale } from "../../utilities";
import { Button } from "../../elements";

function CaseDetails(props) {
  const [caseId, setCaseId] = useState(props.match.params.caseId);
  const [caseType, setCaseType] = useState();
  const [caseTitle, setCaseTitle] = useState();
  const [clinicalScenario, setClinicalScenario] = useState();
  const [questions, setQuestions] = useState();
  const [stems, setStems] = useState();
  const [explanation, setExplanation] = useState();
  const [loading, setLoading] = useState(true);
  const [correctAnswerCreated, setCorrectAnswerCreated] = useState(false);

  useEffect(() => {
    const {
      caseType,
      caseTitle,
      clinicalScenario,
      questions,
      stems,
      explanation
    } = props.responseData;
    setCaseType(caseType);
    setCaseTitle(caseTitle);
    setClinicalScenario(clinicalScenario);
    setQuestions(questions);
    setStems(stems);

    props.getUrlPath(props.match.path);

    setLoading(false);

    let masterExplanation = explanation.filter(
      explanation => explanation.is_master === true
    );
    setExplanation(masterExplanation[0].explanation);
    return () => console.log("unmounting...");
  }, []);

  const [selectedChoice, setSelectedChoice] = useState();
  function handleChoiceChange(event) {
    if (!answered) {
      setSelectedChoice(event.target.value);
    }
  }

  const [answered, setAnswered] = useState(false);
  const [correct, setCorrect] = useState();
  useEffect(() => {
    const correctChoice = props.responseData.questions[0].choices.filter(
      choice => {
        if (choice.correct_answer === true) return choice;
      }
    );
    if (correctChoice.length > 0) {
      setCorrectAnswerCreated(true);
    }
  });
  function handleAnswer(event) {
    // 1. Get the correct answer choice
    const correctChoice = props.responseData.questions[0].choices.filter(
      choice => {
        if (choice.correct_answer === true) return choice;
      }
    );

    // 2. Compare user's selected choice with the correct answer choice
    // 3. Set case state to 'answered'
    if (selectedChoice == correctChoice[0].id) {
      console.log("Correct Answer");
      setCorrect(true);
    } else {
      console.log("Wrong Answer");
    }

    setAnswered(true);
  }

  function goBack() {
    props.history.goBack();
  }

  if (loading) {
    return (
      <CaseSubContainer>
        <h2>Loading...</h2>
      </CaseSubContainer>
    );
  } else {
    return (
      <CaseMainContainer answered={answered} correct={correct}>
        <TitleCaseBlock answered={answered} correct={correct}>
          <BackButton onClick={goBack} answered={answered}>
            <FontAwesomeIcon icon={faAngleLeft} size="2x" />
          </BackButton>
          <h2>{caseTitle ? caseTitle : "Untitiled Case"}</h2>
        </TitleCaseBlock>
        {caseType == "clinical scenario" ? (
          <CaseSubContainer>
            <MainCaseBlock>
              <CaseBlock>
                <CodeHealthEditorReadOnly
                  label="Clinical Scenario"
                  propsEditorState={stems[0].stem}
                  onChange={true}
                />
              </CaseBlock>
              <ExplanainationCaseBlock answered={answered}>
                <CodeHealthEditorReadOnly
                  label="Explanation"
                  propsEditorState={explanation}
                />
              </ExplanainationCaseBlock>
            </MainCaseBlock>

            <ChoicesBlock>
              <CaseBlock>
                <CodeHealthEditorReadOnly
                  label="Question"
                  propsEditorState={props.responseData.questions[0].question}
                />
                <AnswerChoicesContainer>
                  {props.responseData.questions[0].choices
                    ? props.responseData.questions[0].choices.map(choice => (
                        <ChoiceContainer key={choice.id}>
                          <StyleChoiceRadio>
                            <label>
                              <input
                                type="radio"
                                value={choice.id}
                                checked={choice.id == selectedChoice}
                                onChange={event => handleChoiceChange(event)}
                              />
                              <Check
                                selected={choice.id == selectedChoice}
                                answered={answered}
                                correct={correct}
                              />
                              <CheckText
                                selected={choice.id == selectedChoice}
                                answered={answered}
                                correct={correct}
                              >
                                {answered && choice.correct_answer && (
                                  <div className="correct-label">
                                    Correct Answer
                                  </div>
                                )}
                                <CodeHealthEditorReadOnly
                                  key={choice.id}
                                  propsEditorState={choice.choice_textfield}
                                />
                              </CheckText>
                            </label>
                          </StyleChoiceRadio>
                        </ChoiceContainer>
                      ))
                    : null}
                </AnswerChoicesContainer>
                {correctAnswerCreated ? (
                  <FullSizeButton
                    disabled={!selectedChoice}
                    onClick={handleAnswer}
                    answered={answered}
                  >
                    SUBMIT ANSWER
                  </FullSizeButton>
                ) : (
                  <InfoText>
                    You have not yet selected a correct answer
                  </InfoText>
                )}
              </CaseBlock>
            </ChoicesBlock>
          </CaseSubContainer>
        ) : (
          <CaseSubContainer>
            <MainCaseBlock>
              <CaseBlock>
                <CodeHealthEditorReadOnly
                  label="Question"
                  propsEditorState={props.responseData.questions[0].question}
                />
              </CaseBlock>
              <ExplanainationCaseBlock answered={answered}>
                <CodeHealthEditorReadOnly
                  label="Explanation"
                  propsEditorState={explanation}
                />
              </ExplanainationCaseBlock>
            </MainCaseBlock>

            <ChoicesBlock>
              <CaseBlock>
                <StyledLabel>Answer Choices</StyledLabel>
                <AnswerChoicesContainer>
                  {props.responseData.questions[0].choices
                    ? props.responseData.questions[0].choices.map(choice => (
                        <ChoiceContainer key={choice.id}>
                          <StyleChoiceRadio>
                            <label>
                              <input
                                type="radio"
                                value={choice.id}
                                checked={choice.id == selectedChoice}
                                onChange={event => handleChoiceChange(event)}
                              />
                              <Check
                                selected={choice.id == selectedChoice}
                                answered={answered}
                                correct={correct}
                              />
                              <CheckText
                                selected={choice.id == selectedChoice}
                                answered={answered}
                                correct={correct}
                              >
                                {answered && choice.correct_answer && (
                                  <div className="correct-label">
                                    Correct Answer
                                  </div>
                                )}
                                <CodeHealthEditorReadOnly
                                  key={choice.id}
                                  propsEditorState={choice.choice_textfield}
                                />
                              </CheckText>
                            </label>
                          </StyleChoiceRadio>
                        </ChoiceContainer>
                      ))
                    : null}
                </AnswerChoicesContainer>
                {correctAnswerCreated ? (
                  <FullSizeButton
                    disabled={!selectedChoice}
                    onClick={handleAnswer}
                    answered={answered}
                  >
                    SUBMIT ANSWER
                  </FullSizeButton>
                ) : (
                  <InfoText>
                    You have not yet selected a correct answer
                  </InfoText>
                )}
              </CaseBlock>
            </ChoicesBlock>
          </CaseSubContainer>
        )}
      </CaseMainContainer>
    );
  }
}
const CaseMainContainer = styled.div`
  position: absolute;
  top:0;
  left: 0
  width: 100%;
  min-height: 100%;
  padding: 0px;
  margin: auto;
  padding-bottom: 150px;
  background: ${grayscale.lightGray};
  a {
    color: ${purple.darkLight};
    ${props => props.correct && props.answered && `color: ${green.bright};`}
    ${props => !props.correct && props.answered && `color: ${red.bright};`}
  }
`;
const CaseSubContainer = styled.div`
  padding: 0px;
  max-width: 1100px;
  margin: auto;
  margin-top: 65px;
`;

const MainCaseBlock = styled.div`
  margin: 0 15px 0 11px;
  display: inline-block;
  width: 60%;
  vertical-align: top;
  @media (max-width: 992px) {
    width: 58.4%;
    margin: 0 10px;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;
const ChoicesBlock = styled.div`
  display: inline-block;
  width: 36.5%;
  vertical-align: top;
  @media (max-width: 992px) {
    width: 38.4%;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;

const CaseBlock = styled.div`
  margin: 15px 0;
  background: white;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 25px;
`;
const ExplanainationCaseBlock = styled(CaseBlock)`
  display: none;
  ${props =>
    props.answered &&
    `display: block;
`}
`;

const TitleCaseBlock = styled(CaseBlock)`
  margin: 15px 0;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.05);
  padding: 15px;
  position: fixed;
  top: 0;
  left: 0;
  margin-top: 0;
  z-index: 99;
  width: 100%;
  background: white;
  min-height: 30px;
  h2 {
    font-family: futura-pt, sans-serif;
    margin: 5px;
    max-width: 1100px;
    margin: auto;
    @media (max-width: 1200px) {
      display: inline-block;
      position: relative;
      vertical-align: middle;
      max-width: 76%;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  ${props =>
    props.correct &&
    props.answered &&
    `background: ${green.bright}; color:white;`}
  ${props =>
    !props.correct &&
    props.answered &&
    `background: ${red.bright};color:white;`}
`;

const StyledLabel = styled.div`
  font-family: futura-pt, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: gray;
  align: left;
  margin-bottom: 15px;
`;
const AnswerChoicesContainer = styled.div`
  margin-bottom: 35px;
`;
const ChoiceContainer = styled.div``;
const StyleChoiceRadio = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  width: 100%;
  input {
    display: none;
  }
`;
const Check = styled.div`
  display: inline-block;
  border: 4px solid #999999;
  border-radius: 100%;
  height: 18px;
  width: 18px;
  vertical-align: middle;
  margin-right: 15px;
  cursor: pointer;
  ${props =>
    props.selected &&
    `border: 4px solid ${purple.darkLight}; background: ${purple.darkLight}`}
  ${props =>
    props.selected &&
    props.correct &&
    props.answered &&
    `border: 4px solid ${green.bright}; background:${green.bright}`}
  ${props =>
    props.selected &&
    !props.correct &&
    props.answered &&
    `color: ${red.bright}; border: 4px solid ${red.bright}; background:${
      red.bright
    }`}
`;
const CheckText = styled.div`
  display: inline-block;
  cursor: pointer;
  width: 83%;
  vertical-align: middle;
  ${props => props.selected && `font-weight: bold; color: ${purple.darkLight};`}
  ${props =>
    props.selected &&
    props.correct &&
    props.answered &&
    `color: ${green.bright};`}
  ${props =>
    props.selected &&
    !props.correct &&
    props.answered &&
    `color: ${red.bright}; `}
  .DraftEditor-root {
    line-height: 24px;
  }
  .correct-label {
    font-size:10px;
    font-style: italic;
    position: relative;
    top: 10px;
    color: ${green.bright};
  }
`;
const FullSizeButton = styled.button`
  width: 100%;
  border: 0;
  border-radius: 4px;
  background-color: ${purple.bright};
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  color: white;
  padding: 15px;
  outline: none;
  font-family: futura-pt, sans-serif;
  &:disabled {
    background: ${purple.darkDark};
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    color: gray;
    transform: translateY(-3px);
  }
  ${props => props.answered && `display: none;`}
`;
const BackButton = styled.button`
  border: 0;
  color: black;
  padding: 0;
  outline: none;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 25px;
  transform: translateY(-50%);
  ${props => props.answered && `color: white;`}
  @media (max-width: 1200px) {
    display: inline-block;
    position: relative;
    margin: 0;
    transform: none;
    vertical-align: middle;
    left: 0;
    margin: 0 25px 0 15px;
  }
`;
const InfoText = styled.div`
  color: gray;
  font-style: italic;
  font-family: adobe-text-pro, serif;
  text-align: center;
`;

export default CaseDetails;
