import React, { Component } from "react";
import {
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw
} from "draft-js";

import debounce from "lodash/debounce";
import { url } from "../settings";

// Dependencies
import axios from "axios";

// Styles
import styled from "styled-components";

const InputContainer = styled.div`
  padding: 15px 0;
`;

const TextInput = styled.div`
  padding: 8px;
  border: 1px solid lightgray;
  border-radius: 4px;
  .DraftEditor-root {
      font-family: adobe-text-pro, serif;
      font-size: 18px;
      line-height: 30px;
  }
`;

class CodeHealthInput extends Component {
  state = {};

  componentDidMount() {
    if (this.props.InputText) {
      this.setState({
        editorState: EditorState.createWithContent(
          convertFromRaw(JSON.parse(this.props.InputText))
        ),
        mounted: true
      });
    } else {
      this.setState({ editorState: EditorState.createEmpty(), mounted: true });
    }
  }

  onChange = editorState => {
    if (this.state.mounted) {
      const contentState = editorState.getCurrentContent();
      const choiceId = this.props.value;
      const questionId = this.props.questionId;

      this.props.handleChange(choiceId, contentState, questionId);

      this.setState({ editorState });
    }
  };

  handleKeyCommand = command => {
    const newState = RichUtils.handleKeyCommand(
      this.state.editorState,
      command
    );
    if (newState) {
      this.onChange(newState);
      return "handled";
    }
    return "not-handled";
  };

  focus = () => {
    this.editor.focus();
  };

  render() {
    if (!this.state.editorState) {
      return <h3 className="loading">Loading...</h3>;
    } else {
      return (
        <TextInput onClick={this.focus}>
          <Editor
            readOnly={this.props.readOnly}
            editorState={this.state.editorState}
            onChange={this.onChange}
            handleKeyCommand={this.handleKeyCommand}
            placeholder="hello world"
            ref={element => {
              this.editor = element;
            }}
          />
        </TextInput>
      );
    }
  }
}

export default CodeHealthInput;
