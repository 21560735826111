import React, { Component, Fragment } from "react";
import {
  EditorState,
  RichUtils,
  AtomicBlockUtils,
  convertToRaw,
  convertFromRaw
} from "draft-js";
import Editor, { composeDecorators } from "draft-js-plugins-editor";

// Components
import VideoButton from "./VideoButton";

// UI
import AlertDialog from "../UI/AlertDialog";

// Dependencies
import axios from "axios";
import debounce from "lodash/debounce";
import { url } from "../../settings";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faVideo } from "@fortawesome/pro-light-svg-icons";

// Plugins
import createToolbarPlugin, { Separator } from "draft-js-static-toolbar-plugin";
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  CodeButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
  CodeBlockButton
} from "draft-js-buttons";
import createImagePlugin from "draft-js-image-plugin";
import createAlignmentPlugin from "draft-js-alignment-plugin";
import createFocusPlugin from "draft-js-focus-plugin";
import createLinkPlugin from "draft-js-anchor-plugin";
import createSideToolbarPlugin from "draft-js-side-toolbar-plugin";
import createVideoPlugin from "draft-js-video-plugin";
import createLinkifyPlugin from "draft-js-linkify-plugin";

// Styles
import styled from "styled-components";
import "draft-js-static-toolbar-plugin/lib/plugin.css";
import "draft-js-image-plugin/lib/plugin.css";
import "draft-js-alignment-plugin/lib/plugin.css";
import "draft-js-anchor-plugin/lib/plugin.css";
import "draft-js-focus-plugin/lib/plugin.css";
import "draft-js-side-toolbar-plugin/lib/plugin.css";
import "draft-js-video-plugin/lib/plugin.css";
import "draft-js-linkify-plugin/lib/plugin.css";

// Styles
const EditorContainer = styled.div`
  position: relative;
  .DraftEditor-root {
    padding: 5px 0;
    margin: 5px 0;
    font-family: adobe-text-pro, serif;
    font-size: 18px;
    line-height: 30px;
    min-height: 75px;
    ${props =>
      props.hasFocus &&
      `background: #FAFAFA; box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);`}
    figure {
      margin: 10px 0;
      img {
        max-width: 100%;
      }
    }
  }
`;
const StyledLabel = styled.div`
  font-family: futura-pt, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: gray;
  align: left;
`;
const ImageButton = styled.button`
  background: #fbfbfb;
  color: #888;
  font-size: 18px;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px;
  outline: 0;
  &:hover {
    background: #f3f3f3;
    outline: 0;
  }
`;

class CodeHealthEditor extends Component {
  constructor(props) {
    super(props);

    // Plugins
    const linkPlugin = createLinkPlugin();
    const linkifyPlugin = createLinkifyPlugin();

    const sideToolbarPlugin = createSideToolbarPlugin({});
    const { SideToolbar } = sideToolbarPlugin;

    const staticToolbarPlugin = createToolbarPlugin();
    const { Toolbar } = staticToolbarPlugin;

    const videoPlugin = createVideoPlugin();
    // const { types } = videoPlugin;

    const focusPlugin = createFocusPlugin();
    const alignmentPlugin = createAlignmentPlugin();
    const { AlignmentTool } = alignmentPlugin;

    const decorator = composeDecorators(
      alignmentPlugin.decorator,
      focusPlugin.decorator
    );
    const imagePlugin = createImagePlugin({ decorator });

    this.plugins = [
      focusPlugin,
      alignmentPlugin,
      imagePlugin,
      linkPlugin,
      sideToolbarPlugin,
      videoPlugin,
      staticToolbarPlugin,
      linkifyPlugin
    ];

    this.components = {
      AlignmentTool,
      linkPlugin,
      SideToolbar,
      Toolbar,
      videoPlugin
    };

    this.state = {
      largeFileSize: false,
      hasFocus: false
    };
  }

  componentDidMount() {
    console.log("mounted Editor");

    if (this.props.propsEditorState) {
      this.setState({
        editorState: EditorState.createWithContent(
          convertFromRaw(JSON.parse(this.props.propsEditorState))
        ),
        mounted: true
      });
    } else {
      this.setState({ editorState: EditorState.createEmpty(), mounted: true });
    }
  }

  onChange = editorState => {
    let idData;

    if (this.props.contentType == "question") {
      idData = this.props.questionId;
    } else if (this.props.contentType == "explanation") {
      idData = this.props.explanationId;
    } else if (this.props.contentType == "stem") {
      idData = this.props.stemId;
    }

    if (this.state.mounted) {
      const contentState = editorState.getCurrentContent();

      this.props.handleChange(contentState, this.props.contentType, idData);

      this.setState({ editorState });
    }
  };

  handleKeyCommand = command => {
    console.log("key command");
    const newState = RichUtils.handleKeyCommand(
      this.state.editorState,
      command
    );
    if (newState) {
      this.onChange(newState);
      return "handled";
    }
    return "not-handled";
  };

  focus = () => {
    this.editor.focus();
  };

  addImage = () => {
    let file;
    const imgTypes = /image\//;
    const virtualElement = document.createElement("input");

    virtualElement.setAttribute("type", "file");
    virtualElement.setAttribute("accept", "image/*");
    virtualElement.click();

    // user opened (selected) files on the popup----proceed further to handle it / upload
    virtualElement.addEventListener("change", () => {
      file = virtualElement.files[0];
      const fileReader = new FileReader();

      if (file.size > 8388608) {
        console.log("FILE TOO BIG");
        this.handleAlert(true);
        return null;
      }

      fileReader.onerror = e => {
        console.log(
          `Unable to proceed with the file requested. Error: ${e.detail}`
        );
      };

      // user submits an image successfully
      fileReader.onload = () => {
        if (imgTypes.test(file.type)) {
          axios
            .post(`${url}/api/v0.1/handle-case-inline-images/`, {
              caseId: this.props.caseId,
              photo: fileReader.result,
              filename: file.name,
              fileType: file.type
            })
            .then(response => {
              this.createImage(response.data.photoURL);
            });
        } else {
          // can handle some modal/popup/tooltip alerting user of wrong file type
          console.error("The file type requested is not an image type!");
          return false;
        }
      };
      // finally, begin loading the image and fire the event handlers
      fileReader.readAsDataURL(file);
    });
  };

  createImage = src => {
    // src should be image data or an image URL
    const urlType = "IMAGE";
    const editorState = this.state.editorState;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      urlType,
      "IMMUTABLE",
      { src }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = AtomicBlockUtils.insertAtomicBlock(
      editorState,
      entityKey,
      " "
    );
    this.onChange(
      EditorState.forceSelection(
        newEditorState,
        newEditorState.getCurrentContent().getSelectionAfter()
      )
    );
  };

  handleAlert = openState => {
    this.setState({
      largeFileSize: openState
    });
  };

  render() {
    const {
      AlignmentTool,
      linkPlugin,
      SideToolbar,
      videoPlugin,
      Toolbar
    } = this.components;

    if (!this.state.editorState) {
      return <h3 className="loading">Loading...</h3>;
    } else {
      return (
        <Fragment>
          <EditorContainer
            onFocus={() => this.setState({ hasFocus: true })}
            hasFocus={this.state.hasFocus}
          >
            <StyledLabel>{this.props.label}</StyledLabel>
            <Editor
              editorState={this.state.editorState}
              onChange={this.onChange}
              handleKeyCommand={this.handleKeyCommand}
              plugins={this.plugins}
              placeholder={this.props.placeholder}
              ref={element => {
                this.editor = element;
              }}
            />
            {this.state.hasFocus && (
              <Toolbar>
                {// may be use React.Fragment instead of div to improve perfomance after React 16
                externalProps => (
                  <Fragment>
                    <BoldButton {...externalProps} />
                    <ItalicButton {...externalProps} />
                    <UnderlineButton {...externalProps} />
                    <linkPlugin.LinkButton {...externalProps} />
                    <Separator {...externalProps} />
                    <HeadlineOneButton {...externalProps} />
                    <HeadlineTwoButton {...externalProps} />
                    <Separator {...externalProps} />
                    <UnorderedListButton {...externalProps} />
                    <OrderedListButton {...externalProps} />
                    <ImageButton onClick={this.addImage}>
                      <FontAwesomeIcon icon={faImage} />
                    </ImageButton>
                    <VideoButton
                      editorState={this.state.editorState}
                      onChange={this.onChange}
                      modifier={videoPlugin.addVideo}
                    >
                      <FontAwesomeIcon icon={faVideo} />
                    </VideoButton>
                  </Fragment>
                )}
              </Toolbar>
            )}

            <AlignmentTool />
          </EditorContainer>

          <AlertDialog
            open={this.state.largeFileSize}
            handleClose={() => this.handleAlert(false)}
            dialogTitle="Image is too large"
            dialogText="The image you chose is large than the maximum upload size (8MB). Please select a smaller file."
            dialogButton="Okay"
          />
        </Fragment>
      );
    }
  }
}

export default CodeHealthEditor;
