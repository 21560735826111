import React, { Component } from "react";
import { EditorState, RichUtils, convertToRaw, convertFromRaw } from "draft-js";
import Editor from "draft-js-plugins-editor";

import debounce from "lodash/debounce";
import { url } from "../../settings";

// Styles
import styled from "styled-components";

const EditorContainer = styled.div`
  padding: 15px 0;
  .DraftEditor-root {
      padding: 5px 0;
      margin: 5px 0;
      font-family: adobe-text-pro, serif;
      font-size: 18px;
      line-height: 30px;
    ${props => props.hasFocus && `background: #FAFAFA; box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1); padding: 15px;`}
  }
`;
const StyledLabel = styled.div`
  font-family: futura-pt, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: gray;
  align: left;

`;

class CodeHealthEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.setState({ mounted: true });

    if (this.props.propsEditorState) {
      this.setState({
        editorState: EditorState.createWithContent(
          convertFromRaw(JSON.parse(this.props.propsEditorState))
        ),
        mounted: true
      });
    } else {
      this.setState({ editorState: EditorState.createEmpty(), mounted: true });
    }
  }

  onChange = editorState => {
      let idData;

      if (this.props.contentType == "question") {
        idData = this.props.questionId;
      } else if (this.props.contentType == "explanation") {
        idData = this.props.explanationId;
      } else if (this.props.contentType == "stem") {
        idData = this.props.stemId;
      }

      if (this.state.mounted) {
        const contentState = editorState.getCurrentContent();

        this.props.handleChange(contentState, this.props.contentType, idData);

        this.setState({ editorState });
      }
  };

  saveContent = content => {
    window.localStorage.setItem(
      "content",
      JSON.stringify(convertToRaw(content))
    );
  };

  handleKeyCommand = command => {
    const newState = RichUtils.handleKeyCommand(
      this.state.editorState,
      command
    );
    if (newState) {
      this.onChange(newState);
      return "handled";
    }
    return "not-handled";
  };

  render() {
    if (!this.state.editorState) {
      return <h3 className="loading">Loading...</h3>;
    } else {
      return (
        <EditorContainer>
          <StyledLabel>{this.props.label}</StyledLabel>
          <Editor
            editorState={this.state.editorState}
            onChange={(editorState) => this.onChange(editorState)}
            handleKeyCommand={this.handleKeyCommand}
            placeholder={this.props.placeholder}
            readOnly={this.readOnly}
          />
        </EditorContainer>
      );
    }
  }
}

export default CodeHealthEditor;
