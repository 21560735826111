import styled from "styled-components";
import { purple } from "../utilities";

export const Button = styled.button`
  border: 0;
  border-radius: 4px;
  background: ${purple.darkDark};
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  color: white;
  padding: 15px;
  outline: none;
  font-family: futura-pt, sans-serif;
  &:hover {
    background-color: ${purple.bright};
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
    color: #fff;
    transform: translateY(-3px);
  }
`;
