import React from "react";
import UserAvatar from "./UserAvatar";

// Dependencies
import axios from "axios";

// Material UI
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";

// Styles
import styled from "styled-components";
import { HeaderComponent } from "./HeaderStyles";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/pro-regular-svg-icons";
import { faBars, faSearch } from "@fortawesome/pro-solid-svg-icons";
import { faHeart } from "@fortawesome/pro-regular-svg-icons";
import { faHistory } from "@fortawesome/pro-regular-svg-icons";
import {
  faUserCircle,
  faCog,
  faFolder,
  faPlus,
  faChartLine,
  faSignOut,
  faAward,
  faQuestionSquare,
} from "@fortawesome/pro-regular-svg-icons";

// Apollo, GraphQL
import { Query, ApolloConsumer } from "react-apollo";
import gql from "graphql-tag";

const CURRENT_USER = gql`
  query CURRENT_USER {
    me {
      id
      username
    }
  }
`;

const styles = {
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  },
  smText: {
    fontSize: 10
  },
  subheader: {
    backgroundColor: "white"
  },
  avatarContainer: {
    display: "inline-block"
  },
  menuAnchor: {
    color: "#333",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none"
    }
  }
};

class DrawerMenuRight extends React.Component {
  state = {
    right: false
  };

  clickAvatar = () => {
    this.setState({
      ["right"]: true
    });
  };

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open
    });
  };

  render() {
    const { classes } = this.props;

    const sideList = (
      <div className={classes.list}>
        <Query query={CURRENT_USER}>
          {({ loading, data }) => {
            if (loading) return "Loading...";
            const { me } = data;
            return (
              <List>
                <a href={`/${me.username}`} className="menu-anchor">
                  <ListItem button>
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faUserCircle} />
                    </ListItemIcon>
                    <ListItemText disableTypography>
                      <span className="codehealth-font-family sm">
                        {me.username}
                        <div className={classes.smText}>View Profile</div>
                      </span>
                    </ListItemText>
                  </ListItem>
                </a>
              </List>
            );
          }}
        </Query>

        <Divider />

        <List>
          <a href="/account/settings/" className="menu-anchor">
            <ListItem button>
              <ListItemIcon>
                <FontAwesomeIcon icon={faCog} />
              </ListItemIcon>
              <ListItemText disableTypography>
                <span className="codehealth-font-family sm">
                  Account Settings
                </span>
              </ListItemText>
            </ListItem>
          </a>
        </List>

        <Divider />

        <List>
          <a href="/cases/new-case" className="menu-anchor">
            <ListItem button>
              <ListItemIcon>
                <FontAwesomeIcon icon={faPlus} />
              </ListItemIcon>
              <ListItemText disableTypography>
                <span className="codehealth-font-family sm">Create a Case</span>
              </ListItemText>
            </ListItem>
          </a>

          <a href="/dashboard/?v=allContent&p=all" className="menu-anchor">
            <ListItem button>
              <ListItemIcon>
                <FontAwesomeIcon icon={faFolder} />
              </ListItemIcon>
              <ListItemText disableTypography>
                <span className="codehealth-font-family sm">
                  Manage Your Content
                </span>
              </ListItemText>
            </ListItem>
          </a>

          <a href="/dashboard" className="menu-anchor">
            <ListItem button>
              <ListItemIcon>
                <FontAwesomeIcon icon={faChartLine} />
              </ListItemIcon>
              <ListItemText disableTypography>
                <span className="codehealth-font-family sm">Your Stats</span>
              </ListItemText>
            </ListItem>
          </a>
        </List>

        <Divider />

        <List>
          <a href="/account/subscribe/" className="menu-anchor">
            <ListItem button>
              <ListItemIcon>
                <FontAwesomeIcon icon={faAward} />
              </ListItemIcon>
              <ListItemText disableTypography>
                <span className="codehealth-font-family sm">
                  CodeHealth Membership
                </span>
              </ListItemText>
            </ListItem>
          </a>
        </List>

        <Divider />

        <List>
          <a href="/contact" className="menu-anchor">
            <ListItem button>
              <ListItemIcon>
                <FontAwesomeIcon icon={faQuestionSquare} />
              </ListItemIcon>
              <ListItemText disableTypography>
                <span className="codehealth-font-family sm">Contact Us</span>
              </ListItemText>
            </ListItem>
          </a>

          <a href="/logout" className="menu-anchor">
            <ListItem button>
              <ListItemIcon>
                <FontAwesomeIcon icon={faSignOut} />
              </ListItemIcon>
              <ListItemText disableTypography>
                <span className="codehealth-font-family sm">Sign Out</span>
              </ListItemText>
            </ListItem>
          </a>
        </List>
      </div>
    );

    return (
      <div className={classes.avatarContainer}>
        <HeaderComponent>
          <a href="/search" className="menu-anchor">
            <FontAwesomeIcon icon={faSearch} size="lg"/>
          </a>
        </HeaderComponent>

        <HeaderComponent type="avatar">
          <UserAvatar
            toggleDrawer={this.toggleDrawer}
            clickAvatar={this.clickAvatar}
            user={this.props.user}
          />
        </HeaderComponent>

        <Drawer
          anchor="right"
          open={this.state.right}
          onClose={this.toggleDrawer("right", false)}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer("right", false)}
            onKeyDown={this.toggleDrawer("right", false)}
          >
            {sideList}
          </div>
        </Drawer>
      </div>
    );
  }
}

DrawerMenuRight.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DrawerMenuRight);
