import React, { Component, Fragment } from "react";
import logo from "./logo.svg";
import "./App.css";
import { url } from "./settings";

// Dependencies
import { Route, Switch } from "react-router-dom";
import axios from "axios";

// Components
import CreateCase from "./Components/CreateCase";
import SelectCaseType from "./Components/SelectCaseType";
import EditCaseContainer from "./Components/EditCaseContainer";
import CaseDetails from "./Components/CaseDetails/CaseDetails";
import NoMatch from "./Components/NoMatch";


// Styles
import styled from "styled-components";

// Apollo, GraphQL
import { Query, ApolloConsumer } from "react-apollo";
import gql from "graphql-tag";

const CURRENT_USER = gql`
  query CURRENT_USER {
    me {
      id
      username
    }
  }
`;


class App extends Component {
  state = {
    user: ""
  };

  render() {
    return (
      <Fragment>
        <Query query={CURRENT_USER}>
          {({ loading, data }) => {
            if (loading) return "Loading...";
            const { me } = data;
            if (me.id !== "1") {
                return null
            }
            return (
              <Body>
                <Switch>
                  <Route exact path="/" component={SelectCaseType} />
                  <Route path="/edit/:caseId/" component={EditCaseContainer} />
                  <Route component={NoMatch} />
                </Switch>
              </Body>
            );
          }}
        </Query>
      </Fragment>
    );
  }
}
export default App;

const Body = styled.div`
  margin-top: 50px;
`;
