import React, { Component, Fragment } from "react";
import "./Header.css";
import DrawerMenuLeft from "./DrawerMenuLeft";
import DrawerMenuRight from "./DrawerMenuRight";
import { url } from "../../settings";

// Dependencies
import axios from "axios";

// Material UI
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faHome, faBookmark } from "@fortawesome/pro-regular-svg-icons";

// Styles
import styled from "styled-components";

const styles = {
  createContainer: {
    display: "inline-block",
    marginLeft: 35,
    fontSize: 12,
    cursor: "pointer",
    padding: "8px 18px",
    background: "#D23D56",
    borderRadius: 3
  },
  createText: {
    color: "white",
    textDecoration: "none"
  },
  betaTag: {
    fontSize: 8,
    color: "#D23D56",
    verticalAlign: "text-top",
    fontWeight: 600
  },
  "@media (max-width: 960px)": {
    createContainer: {
      display: "none"
    }
  }
};

class App extends Component {
  state = {
    user: {
      username: "",
      avatar: ""
      // subscriptionState: ""
    },
    isLoading: true
  };

  componentDidMount() {
    this.setState({
      isLoading: false
    });
  }

  render() {
    const { classes } = this.props;
    let upgradeButton;

    if (!this.state.user.subscriptionState) {
      upgradeButton = (
        <div className={classes.createContainer}>
          <a href="/account/subscribe/" className={classes.createText}>
            Upgrade
          </a>
        </div>
      );
    } else {
      upgradeButton = null;
    }

    if (this.state.isLoading) {
      return null;
    } else {
      return (
        <Fragment>
          <header className="react-header">
            <div className="header-nav">
              <div className="left-nav">
                <DrawerMenuLeft />
              </div>

              <HeaderLabel>
                <div className="label-text">{this.props.label}</div>
              </HeaderLabel>

              <div className="right-nav">
                <DrawerMenuRight />
              </div>
            </div>
          </header>
        </Fragment>
      );
    }
  }
}
export default withStyles(styles)(App);

const HeaderLabel = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 50px;
  .label-text {
    text-align: center;
    padding: 14px;
  }
`;
