import React, { useState, useEffect, Fragment } from "react";
import { url } from "../settings";

// Dependencies
import axios from "axios";
import debounce from "lodash/debounce";

// Components
import AlertDialog from "./UI/AlertDialog";
import Toggle from "./Toggle/Toggle";

// Styles
import styled from "styled-components";
import { purple } from "../utilities";
import { Button } from "../elements";

export default function EditCaseSettings(props) {
  const [caseId, setCaseId] = useState(props.match.params.caseId);
  const {
    id,
    caseType,
    caseLayout,
    caseIsDraft,
    caseIsPrivate
  } = props.responseData;

  useEffect(() => {
    props.getUrlPath(props.match.path);
  }, []);


  return (
    <div>
      {!props.responseData.caseIsDraft && (
        <Fragment>
          <CaseBlock>
            <InlineStyledLabel>THIS IS A PUBLISHED CASE</InlineStyledLabel>
            <Description>
              If you want to edit this case, you have to first unpublish it.
            </Description>
          </CaseBlock>

          <Line />
        </Fragment>
      )}

      <CaseBlock>
        <InlineStyledLabel>Publish</InlineStyledLabel>
        <Toggle
          caseIs={props.responseData.caseIsDraft}
          toggleState={props.handlePublishState}
        />
        <Description>
          Publishing your case allows the public to view it. After publishing a
          case, "save as you type feature" will be disabled and you will need to
          save changes manually.
        </Description>
      </CaseBlock>
      <Line />
      <CaseBlock>
        <InlineStyledLabel>Public</InlineStyledLabel>
        <Toggle
          caseIs={props.responseData.caseIsPrivate}
          toggleState={props.handlePrivateState}
        />
        {!props.responseData.caseIsPrivate ? (
          <Description>
            Making a case public, allows you to show a case to the general
            public. You may consider turning this off (making a case private) if
            you wanted to only share your case to people who had access to the
            case link.
          </Description>
        ) : (
          <Description>Case is Private.</Description>
        )}
      </CaseBlock>
      <Line />
      <CaseBlock>
        <label>
          <StyledLabel>Miscellaneous Data</StyledLabel>
          <p className="misc-data">ID: {id}</p>
          <p className="misc-data">Hash ID: {caseId}</p>
          <p className="misc-data">Type: {caseType}</p>
          <p className="misc-data">Layout: {caseLayout}</p>
        </label>
      </CaseBlock>
    </div>
  );
}

const CaseBlock = styled.div`
  margin-bottom: 45px;
  textarea {
    width: 100%;
    min-height: 75px;
    border: 1px solid lightgray;
    border-radius: 4px;
    padding: 10px;
    font-family: futura-pt, sans-serif;
    font-size: 16px;
    line-height: 30px;
  }
  .misc-data {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    color: gray;
  }
`;
const Line = styled.hr`
  margin-bottom: 45px;
  border: 1px solid #f2f2f2;
`;
const StyledLabel = styled.div`
  font-family: futura-pt, sans-serif;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
  color: black;
  align: left;
  p {
    margin: 0;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 14px;
    color: gray;
  }
`;
const Description = styled.p`
  font-family: futura-pt, sans-serif;
  margin: 0;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 14px;
  color: gray;
`;
const ClassicButton = styled(Button)`
  margin: 15px 0 25px 0;
`;
const Thumbnail = styled.div`
  img {
    object-fit: cover;
    height: 170px;
    width: 300px;
  }
  p {
    font-family: futura-pt, sans-serif;
    font-weight: 500;
    font-size: 16px;
  }
`;
const StyledSelect = styled.select`
  font-family: futura-pt, sans-serif;
  font-size: 16px;
  outline: none;
`;
const InlineStyledLabel = styled(StyledLabel)`
  display: inline-block;
`;
