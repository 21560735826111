import React, { Component, Fragment } from "react";
import { convertToRaw } from "draft-js";
import { url } from "../settings";

// Dependencies
import axios from "axios";
import debounce from "lodash/debounce";
import { EditorState } from "draft-js";

// Components
import CodeHealthEditor from "./Editors/CodeHealthEditor";
import CodeHealthEditorPure from "./Editors/CodeHealthEditorPure";
import CodeHealthInput from "./CodeHealthInput";

// UI - Icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";

// Styles
import styled, { css } from "styled-components";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.withCredentials = true;

// Styles
const CaseBlock = styled.div`
  margin-bottom: 45px;
`;
const Line = styled.hr`
  margin-bottom: 45px;
  border: 1px solid #f2f2f2;
`;

const ChoiceBlock = styled.div`
  margin-top: 15px;
`;

const StyledLabel = styled.div`
  font-family: futura-pt, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: gray;
  align: left;
`;

const Button = styled.button`
  border: 0;
  border-radius: 4px;
  background: #16111c;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  color: white;
  padding: 15px;
  outline: none;
  font-family: futura-pt, sans-serif;
  &:hover {
    background-color: #5143bd;
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
    color: #fff;
    transform: translateY(-3px);
  }
`;

const AddAnswerChoice = styled(Button)`
  margin: 15px 0 25px 0;
`;

const StyleDeleteChoice = styled.div`
  padding: 8px 0;
  display: inline-block;
  vertical-align: middle;
  button {
    border: none;
    background: none;
    padding: 0 15px 0 10px;
    margin-right: 15px;
  }
`;
const StyleChoiceInput = styled.div`
  padding: 10px 0;
  display: inline-block;
  vertical-align: middle;
  width: 69%;
`;
const StyleChoiceRadio = styled.div`
  padding: 15px 0;
  display: inline-block;
  vertical-align: middle;
  margin-left: 15px;
  input {
    display: none;
  }
`;
const Check = styled.div`
  display: inline-block;
  border: 4px solid #999999;
  border-radius: 100%;
  height: 18px;
  width: 18px;
  vertical-align: middle;
  margin-right: 10px;
  cursor: pointer;
  ${props =>
    props.selected && `border: 4px solid #008c82; background: #008c82;`}
`;
const CheckText = styled.div`
  display: inline-block;
  color: #999999;
  font-family: futura-pt, sans-serif;
  cursor: pointer;
  ${props => props.selected && `font-weight: bold; color: #008c82;`}
`;
const ChoiceContainer = styled.div``;

const CaseTitleText = styled.h1`
  margin: 0;
  input {
    font-family: futura-pt, sans-serif;
    font-size: 28px;
    font-weight: 700;
    display: block;
    border: 0;
    margin: 5px 0;
    width: 100%;
    padding: 5px 0;
  }
`;

class EditCase extends Component {
  state = {
    caseId: this.props.match.params.caseId,
    caseType: "",
    caseTitle: "",
    caseLayout: "",
    stems: [],
    clinicalScenario: "",
    questions: [],
    explanation: "",
    caseIsDraft: true
  };

  componentWillMount() {
    this.setState({
      caseLayout: this.props.responseData.caseLayout
    });
  }

  componentDidMount() {
    const {
      caseType,
      caseTitle,
      clinicalScenario,
      questions,
      stems,
      explanation,
      caseIsDraft
    } = this.props.responseData;

    this.props.getUrlPath(this.props.match.path);

    let filteredExplanation = explanation.filter(
      explanation => explanation.is_master === true
    );

    if (this.props.responseData.caseLayout !== "v3.0") {
      const oldExplanation = this.props.responseData.oldExplanation;
      const oldQuestion = this.props.responseData.oldQuestion;
      const oldChoices = this.props.responseData.oldChoices;

      if (this.props.responseData.caseType == "clinical scenario") {
        const oldStem = this.props.responseData.oldStem;
        this.setState({
          oldExplanation: oldExplanation,
          oldQuestion: oldQuestion,
          oldChoices: oldChoices,
          oldStem: oldStem
        });
      } else {
        this.setState({
          oldExplanation: oldExplanation,
          oldQuestion: oldQuestion,
          oldChoices: oldChoices
        });
      }
    }

    this.setState({
      caseType: caseType,
      caseTitle: caseTitle,
      caseIsDraft: caseIsDraft,
      stems: stems,
      clinicalScenario: clinicalScenario,
      questions: questions,
      explanation: filteredExplanation[0],
      contentFetched: true
    });
    console.log(this.props.responseData);
  }

  componentWillUnmount() {
    console.log("unmounting...");
  }

  createMarkup = html => {
    return { __html: html };
  };

  handleChangeTitle = event => {
    this.props.handleChangeTitle(event.target.value);
    this.setState({ caseTitle: event.target.value });
    this.saveCaseTitle(event.target.value);
  };
  saveCaseTitle = debounce(caseTitle => {
    axios.post(`${url}/api/v0.1/edit-case/`, {
      caseId: this.state.caseId,
      content: caseTitle,
      contentType: "caseTitle"
    });
  }, 1000);

  handleChange = debounce((contentState, contentType, idData) => {
    if (contentType === "question") {
      axios.post(`${url}/api/v0.1/edit-case/`, {
        caseId: this.state.caseId,
        content: JSON.stringify(convertToRaw(contentState)),
        contentType: contentType,
        questionId: idData
      });
    } else if (contentType === "explanation") {
      axios.post(`${url}/api/v0.1/edit-case/`, {
        caseId: this.state.caseId,
        content: JSON.stringify(convertToRaw(contentState)),
        contentType: contentType,
        explanationId: idData
      });
    } else if (contentType === "stem") {
      axios.post(`${url}/api/v0.1/edit-case/`, {
        caseId: this.state.caseId,
        content: JSON.stringify(convertToRaw(contentState)),
        contentType: contentType,
        stemId: idData
      });
    } else {
      axios.post(`${url}/api/v0.1/edit-case/`, {
        caseId: this.state.caseId,
        content: JSON.stringify(convertToRaw(contentState)),
        contentType: contentType
      });
    }
    this.props.handleChangeGlobal(
      JSON.stringify(convertToRaw(contentState)),
      contentType,
      idData
    );
  }, 1000);

  createAnswerChoice = event => {
    const questionId = event.target.value;

    axios
      .post(`${url}/api/v0.1/edit-case-choices/`, {
        caseId: this.state.caseId,
        choiceAction: "Create",
        questionId: questionId
      })
      .then(response => {
        const newQuestion = this.state.questions.map(question => {
          if (question.id != response.data.questionId) return question;

          return {
            ...question,
            choices: response.data.choices
          };
        });

        this.setState({ questions: newQuestion });
        this.props.handleChangeGlobal(
          response.data.choices,
          "choices",
          response.data.questionId
        );
      });
  };

  editAnswerChoice = (choiceId, contentState, questionId) => {
    if (this.state.caseLayout !== "v3.0") {
      axios
        .post(`${url}/api/v0.1/edit-case-choices/`, {
          caseId: this.state.caseId,
          choiceId: choiceId,
          questionId: this.state.questions[0].id,
          content: JSON.stringify(convertToRaw(contentState)),
          choiceAction: "Edit",
          oldChoice: true
        })
        .then(response => {
          console.log(response.data.questionId);
          const newQuestion = this.state.questions.map(question => {
            if (question.id != response.data.questionId) return question;

            return {
              ...question,
              choices: response.data.choices
            };
          });

          this.setState({ questions: newQuestion });
          console.log(response.data.choices);
          this.props.handleChangeGlobal(
            response.data.choices,
            "choices",
            response.data.questionId
          );
        });
    } else {
      axios
        .post(`${url}/api/v0.1/edit-case-choices/`, {
          caseId: this.state.caseId,
          choiceId: choiceId,
          questionId: questionId,
          content: JSON.stringify(convertToRaw(contentState)),
          choiceAction: "Edit",
          oldChoice: false
        })
        .then(response => {
          console.log(response.data.questionId);
          const newQuestion = this.state.questions.map(question => {
            if (question.id != response.data.questionId) return question;

            return {
              ...question,
              choices: response.data.choices
            };
          });

          this.setState({ questions: newQuestion });
          console.log(response.data.choices);
          this.props.handleChangeGlobal(
            response.data.choices,
            "choices",
            response.data.questionId
          );
        });
    }
  };

  deleteChoice = (event, questionId) => {
    event.preventDefault();
    console.log(event.currentTarget);
    const choiceId = event.currentTarget.value;
    console.log(choiceId);

    axios
      .post(`${url}/api/v0.1/edit-case-choices/`, {
        caseId: this.state.caseId,
        choiceId: choiceId,
        questionId: questionId,
        choiceAction: "Delete"
      })
      .then(response => {
        const newQuestion = this.state.questions.map(question => {
          if (question.id != response.data.questionId) return question;

          return {
            ...question,
            choices: response.data.choices
          };
        });

        this.setState({ questions: newQuestion });
        this.props.handleChangeGlobal(
          response.data.choices,
          "choices",
          response.data.questionId
        );
      });
  };

  handleChoiceChange = (event, questionId) => {
    const selectedChoice = event.target.value;
    axios
      .post(`${url}/api/v0.1/edit-case-choices/`, {
        caseId: this.state.caseId,
        choiceId: selectedChoice,
        questionId: questionId,
        choiceAction: "CorrectAnswer"
      })
      .then(response => {
        const newQuestion = this.state.questions.map(question => {
          if (question.id != response.data.questionId) return question;

          return {
            ...question,
            choices: response.data.choices
          };
        });
        this.props.handleChangeGlobal(
          response.data.choices,
          "choices",
          response.data.questionId
        );
        this.setState({ questions: newQuestion });
      });
  };

  convertCase = () => {
    axios
      .post(`${url}/api/v0.1/conversion/`, {
        caseId: this.state.caseId
      })
      .then(response => {
        this.setState({ caseLayout: "v3.0" });
      });
  };

  render() {
    if (!this.state.contentFetched) {
      return <h3 className="loading">Loading...</h3>;
    } else {
      return (
        <Fragment>
          <CaseBlock>
            <label>
              <StyledLabel>Case Title</StyledLabel>
              <CaseTitleText>
                <input
                  type="text"
                  value={this.state.caseTitle}
                  onChange={this.handleChangeTitle}
                  placeholder="Untitled Case"
                />
              </CaseTitleText>
            </label>
          </CaseBlock>

          <Line />

          {this.state.caseType == "clinical scenario" && (
            <Fragment>
              <CaseBlock>
                {this.state.caseLayout !== "v3.0" && (
                  // OLD STEM
                  <Fragment>
                    <StyledLabel>Old Stem</StyledLabel>
                    <div
                      dangerouslySetInnerHTML={this.createMarkup(
                        this.state.oldStem
                      )}
                    />
                  </Fragment>
                )}
                {Object.keys(this.state.stems).map(key => (
                  <CodeHealthEditor
                    key={key}
                    label="Clinical Scenario"
                    handleChange={this.handleChange}
                    caseId={this.state.caseId}
                    propsEditorState={this.state.stems[key].stem}
                    contentType="stem"
                    stemId={this.state.stems[key].id}
                    placeholder="The clinical scenario you want to present (A patient with chest pain...)"
                  />
                ))}
              </CaseBlock>
              <Line />
            </Fragment>
          )}

          {Object.keys(this.state.questions).map(key => (
            <div key={key}>
              <CaseBlock>
                {this.state.caseLayout !== "v3.0" && (
                  // OLD QUESTION
                  <Fragment>
                    <StyledLabel>Old Question</StyledLabel>
                    <div
                      dangerouslySetInnerHTML={this.createMarkup(
                        this.state.oldQuestion
                      )}
                    />
                  </Fragment>
                )}
                <CodeHealthEditorPure
                  label="Question"
                  handleChange={this.handleChange}
                  caseId={this.state.caseId}
                  propsEditorState={this.state.questions[key].question}
                  contentType="question"
                  placeholder="Ask a question (i.e. What is the likely diagnosis?)"
                  questionId={this.state.questions[key].id}
                />
              </CaseBlock>

              <Line />

              <CaseBlock>
                <StyledLabel>Answer Choices:</StyledLabel>
                <ChoiceBlock>
                  {this.state.questions[key].choices
                    ? this.state.questions[key].choices.map(choice => (
                        <ChoiceContainer key={choice.id}>
                          <StyleDeleteChoice>
                            <button
                              onClick={event =>
                                this.deleteChoice(
                                  event,
                                  this.state.questions[key].id
                                )
                              }
                              value={choice.id}
                            >
                              <FontAwesomeIcon icon={faTrashAlt} size="2x" />
                            </button>
                          </StyleDeleteChoice>

                          <StyleChoiceInput>
                            <CodeHealthInput
                              value={choice.id}
                              questionId={this.state.questions[key].id}
                              InputText={choice.choice_textfield}
                              handleChange={this.editAnswerChoice}
                            />
                          </StyleChoiceInput>

                          <StyleChoiceRadio>
                            <label>
                              <input
                                type="radio"
                                value={choice.id}
                                checked={choice.correct_answer}
                                onChange={event =>
                                  this.handleChoiceChange(
                                    event,
                                    this.state.questions[key].id
                                  )
                                }
                              />
                              <Check selected={choice.correct_answer} />
                              <CheckText selected={choice.correct_answer}>
                                Correct Answer
                              </CheckText>
                            </label>
                          </StyleChoiceRadio>
                        </ChoiceContainer>
                      ))
                    : null}
                </ChoiceBlock>

                {this.state.caseLayout !== "v3.0" && (
                  <ChoiceBlock>
                    <StyledLabel>Old Choices Conversion</StyledLabel>
                    {this.state.oldChoices.map(choice => (
                      <ChoiceContainer key={choice.id}>
                        <StyleChoiceInput>
                          <div>{choice.choice_text}</div>
                          <CodeHealthInput
                            value={choice.id}
                            InputText={choice.choice_textfield}
                            handleChange={this.editAnswerChoice}
                          />
                        </StyleChoiceInput>
                      </ChoiceContainer>
                    ))}
                  </ChoiceBlock>
                )}

                <AddAnswerChoice
                  onClick={this.createAnswerChoice}
                  value={this.state.questions[key].id}
                >
                  ADD ANSWER CHOICE
                </AddAnswerChoice>
              </CaseBlock>
              <Line />
            </div>
          ))}

          <CaseBlock>
            {this.state.caseLayout !== "v3.0" && (
              // OLD EXPLANATION
              <Fragment>
                <StyledLabel>Old Explanation</StyledLabel>
                <div
                  dangerouslySetInnerHTML={this.createMarkup(
                    this.state.oldExplanation
                  )}
                />
              </Fragment>
            )}
            <CodeHealthEditor
              label="Explanation"
              handleChange={this.handleChange}
              caseId={this.state.caseId}
              propsEditorState={this.state.explanation.explanation}
              contentType="explanation"
              placeholder="Explain and discuss why the answer was the best choice."
              explanationId={this.state.explanation.id}
            />
          </CaseBlock>
          {this.props.responseData.caseLayout !== "v3.0" && (
            <Button onClick={this.convertCase}>CONVERT CASE v3.0</Button>
          )}
        </Fragment>
      );
    }
  }
}

export default EditCase;
