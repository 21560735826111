import React, { Component, Fragment } from "react";
import { convertToRaw, convertFromRaw } from "draft-js";
import { url } from "../settings";

// Dependencies
import axios from "axios";
import debounce from "lodash/debounce";

// Components
import CodeHealthEditor from "./Editors/CodeHealthEditor";

class CreateCase extends Component {
  state = {
    caseId: 12,
    caseTitle: "",
    clinicalScenario: "",
    explanation: ""
  };

  componentDidMount() {
    console.log("mounted create case");
    // 1. Get Current Case ID
    // 2. Set State CaseId
  }

  handleChangeTitle = event => {
    this.setState({ caseTitle: event.target.value });
  };

  handleChangeclinicalScenario = debounce(contentState => {
      console.log('send to server')
    // this.setState({ clinicalScenario: contentState });

    axios.post(`${url}/api/v0.1/edit-case/`, {
      caseId: this.state.caseId,
      content: JSON.stringify(convertToRaw(contentState))
    });
  }, 1000);

  handleChangeExplanation = contentState => {
    this.setState({ explanation: contentState });
  };

  createCase = () => {
    console.log("submit case");
    axios
      .post(`${url}/api/v0.1/create-case/`)
      .then(response => {
        console.log(response);
        this.setState({
          id: response.data.caseId
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  render() {
    return (
      <Fragment>
        <label>
          Case Title:
          <input
            type="text"
            value={this.state.caseTitle}
            onChange={this.handleChangeTitle}
          />
        </label>

        <CodeHealthEditor
          label="Clinical Scenario"
          handleChange={this.handleChangeclinicalScenario}
          caseId={this.state.caseId}
          fetchURL={`api/v0.1/fetch-case/?case=${this.state.caseId}`}
          fetchData={'data=clinical_scenario'}
        />

        <button onClick={this.createCase}>Create Case</button>
      </Fragment>
    );
  }
}

export default CreateCase;
