import React, { useState, useEffect, Fragment } from "react";
import { url } from "../settings";

// Dependencies
import axios from "axios";
import debounce from "lodash/debounce";

// Components
import AlertDialog from "./UI/AlertDialog";

// Context
import { CaseContext } from "../Context/CaseContext";

// Styles
import styled from "styled-components";
import { purple } from "../utilities";
import { Button } from "../elements";

function EditCaseSettings(props) {
  const [caseId, setCaseId] = useState(props.match.params.caseId);
  const [caseTitle, setCaseTitle] = useState();
  const [largeFileSize, setLargeFileSize] = useState(false);
  const [caseThumbnail, setCaseThumbnail] = useState();
  const [hashtags, setHashtags] = useState("");
  const [category, setCategtory] = useState("");

  const { id, caseType, caseLayout } = props.responseData;

  function handleCategoryChange(e) {
    const selectedCategory = e.target.value;
    setCategtory(selectedCategory);
    axios.post(`${url}/api/v0.1/edit-case/`, {
      caseId: caseId,
      content: selectedCategory,
      contentType: "category"
    });
    props.handleChangeSettings(selectedCategory, "category");
  }

  useEffect(() => {
    const {
      caseTitle,
      caseHashtags,
      caseThumbnail,
      caseCategory
    } = props.responseData;
    setHashtags(caseHashtags);
    setCaseThumbnail(caseThumbnail);
    setCategtory(caseCategory);
    setCaseTitle(caseTitle);
    props.getUrlPath(props.match.path);

    return () => console.log("unmounting...");
  }, []);

  function handleFileChange(e) {
    const file = e.target.files[0];

    // 8388608 = 8MB
    // 10485760 = 10MB
    if (file.size > 10485760) {
      setLargeFileSize(true);
      e.target.value = null;
    } else {
      var formData = new FormData();
      formData.append("image", file);
      formData.append("photoAction", "upload");
      formData.append("caseId", caseId);
      axios
        .post(`${url}/api/v0.1/edit-case-thumbnail/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          const imgURL = response.data.photo;
          props.handleChangeSettings(imgURL, "updateThumbnail");
        });
    }

    const fileReader = new FileReader();
    fileReader.onload = () => {
      setCaseThumbnail(fileReader.result);
      // props.handleChangeSettings(fileReader.result, "uploadThumbnail")
    };
    fileReader.readAsDataURL(file);
  }

  function deleteThumbnail() {
    setCaseThumbnail(false);
    props.handleChangeSettings(null, "deleteThumbnail");
    var formData = new FormData();
    formData.append("photoAction", "delete");
    formData.append("caseId", caseId);
    axios
      .post(`${url}/api/v0.1/edit-case-thumbnail/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {});
  }

  function handleAlertClose(close) {
    setLargeFileSize(false);
  }

  // HASHTAG LOGIC
  function handleTagChange(event, value) {
    setHashtags(value);
    validateHashtags(event.target.value);
    props.handleChangeSettings(event.target.value, "hashtags");
  }

  const validateHashtags = debounce(tags => {
    //unacceptable chars
    const pattern = new RegExp(/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/);
    // removes hashtags
    const baretags = tags.replace(/\#/g, "");

    if (!pattern.test(baretags)) {
      axios.post(`${url}/api/v0.1/edit-case/`, {
        caseId: caseId,
        caseTags: tags,
        contentType: "tags"
      });
    }
  }, 1000);

  return (
    <div>
      <CaseBlock>
        {props.responseData.caseThumbnail || caseThumbnail ? (
          <Fragment>
            <StyledLabel>
              Case Thumbnail
              <p>A preview of your case's thumbnail and case card.</p>
            </StyledLabel>
            <Thumbnail>
              <img src={props.responseData.caseThumbnail || caseThumbnail} />
              <p>{props.responseData.caseTitle}</p>
            </Thumbnail>
            <ClassicButton onClick={deleteThumbnail}>
              DELETE PHOTO
            </ClassicButton>
          </Fragment>
        ) : (
          <label>
            <StyledLabel>
              Case Thumbnail
              <p>
                The thumbnail you choose will be a graphical representation of
                your case to other people
              </p>
            </StyledLabel>
            <input
              type="file"
              accept="image/*"
              onChange={e => handleFileChange(e)}
            />
          </label>
        )}
      </CaseBlock>
      <AlertDialog
        open={largeFileSize}
        handleClose={() => handleAlertClose(setLargeFileSize)}
        dialogTitle="Image is too large"
        dialogText="The image you chose is large than the maximum upload size (8MB). Please select a smaller file."
        dialogButton="Okay"
      />

      <Line />

      <CaseBlock>
        <label>
          <StyledLabel>
            Category
            <p>
              Selecting a category will group this case into the preselected tag
              from the dropdown menu.
            </p>
          </StyledLabel>
          <StyledSelect
            value={props.responseData.caseCategory}
            onChange={handleCategoryChange}
          >
            <option value="">Select A Category</option>
            <option value="cardiovascular">Cardiovascular</option>
            <option value="dermatology">Dermatology</option>
            <option value="endocrine">Endocrine and Metabolic</option>
            <option value="environmental">Environmental</option>
            <option value="gastrointestinal">Gastrointestinal</option>
            <option value="hematologic">Hematologic</option>
            <option value="infectious">Infectious Disease</option>
            <option value="immunologic">Immunologic</option>
            <option value="musculoskeletal">Musculoskeletal</option>
            <option value="neurologic">Neurologic</option>
            <option value="pharmacology">Pharmacology</option>
            <option value="psychiatric">Psychiatric</option>
            <option value="renal">Renal</option>
            <option value="reproductive">Reproductive</option>
            <option value="respiratory">Respiratory</option>
            <option value="rheumatologic">Rheumatologic</option>
            <option value="other">Other</option>
          </StyledSelect>
        </label>
      </CaseBlock>

      <Line />

      <CaseBlock>
        <label>
          <StyledLabel>
            Hashtags
            <p>
              Just like you hashtag on social media, these will help people sort
              and organize this case. They start with a #. Only numbers and
              letters. No special characters.
            </p>
          </StyledLabel>

          <textarea
            value={props.responseData.caseHashtags}
            onChange={(event, value) => handleTagChange(event, value)}
            placeholder="#ChestPain #Anemia"
          />
        </label>
      </CaseBlock>

      <Line />

      <CaseBlock>
        <label>
          <StyledLabel>Miscellaneous Data</StyledLabel>
          <p className="misc-data">ID: {id}</p>
          <p className="misc-data">Hash ID: {caseId}</p>
          <p className="misc-data">Type: {caseType}</p>
          <p className="misc-data">Layout: {caseLayout}</p>
        </label>
      </CaseBlock>
    </div>
  );
}
export default EditCaseSettings;

const CaseBlock = styled.div`
  margin-bottom: 45px;
  textarea {
    width: 100%;
    min-height: 75px;
    border: 1px solid lightgray;
    border-radius: 4px;
    padding: 10px;
    font-family: futura-pt, sans-serif;
    font-size: 16px;
    line-height: 30px;
  }
  .misc-data {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    color: gray;
  }
`;
const Line = styled.hr`
  margin-bottom: 45px;
  border: 1px solid #f2f2f2;
`;
const StyledLabel = styled.div`
  font-family: futura-pt, sans-serif;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
  color: black;
  align: left;
  p {
    margin: 0;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 14px;
    color: gray;
  }
`;
const Description = styled.p`
  font-family: futura-pt, sans-serif;
  margin: 0;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 14px;
  color: gray;
`;
const ClassicButton = styled(Button)`
  margin: 15px 0 25px 0;
`;
const Thumbnail = styled.div`
  img {
    object-fit: cover;
    height: 170px;
    width: 300px;
  }
  p {
    font-family: futura-pt, sans-serif;
    font-weight: 500;
    font-size: 16px;
  }
`;
const StyledSelect = styled.select`
  font-family: futura-pt, sans-serif;
  font-size: 16px;
  outline: none;
`;
const InlineStyledLabel = styled(StyledLabel)`
  display: inline-block;
`;
