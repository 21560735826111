import React, { useState, useEffect } from "react";
import "./toggle.css";

// Styles
import styled from "styled-components";

export default function Toggle(props) {
  const [toggleState, setToggleState] = useState();

  useEffect(() => {
    if (!props.caseIs) {
      setToggleState(true);
    }
    return () => null;
  }, []);

  function handleClick() {
    setToggleState(!toggleState);
    if (props.caseIs) {
        props.toggleState(false)
    } else {
        props.toggleState(true)
    }

  }

  return (
    <ToggleContainer>
      <label className="switch">
        <Input type="checkbox" checked={toggleState} onClick={handleClick}/>
        <div className="slider round" />
      </label>
      <span>{props.label}</span>
    </ToggleContainer>
  );
}

const ToggleContainer = styled.div`
  display: inline-block;
`;
const Input = styled.input`
  display: none;
`;
