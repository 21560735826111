import React, { useState, useEffect, Fragment } from "react";
import { url } from "../settings";

// Dependencies
import { Route, Link, Redirect } from "react-router-dom";
import debounce from "lodash/debounce";
import axios from "axios";

// Components
import EditCase from "./EditCase";
import EditCaseSettings from "./EditCaseSettings";
import CaseDetails from "./CaseDetails/CaseDetails";
import EditPublishState from "./EditPublishState";
import Header from "./Navigation/Header";

// Context
import { CaseContext } from "../Context/CaseContext";

// Styles
import styled from "styled-components";

const CaseContainer = styled.div`
  padding: 75px 0 250px 0;
  max-width: 1000px;
  margin: auto;
  .public-DraftStyleDefault-block {
    margin-bottom: 10px;
  }
`;

const EditNav = styled.div`
  display: inline-block;
  width: 150px;
  margin-right: 15px;
  vertical-align: top;
  font-family: futura-pt, sans-serif;
  ul {
    margin: 0;
    padding-left: 0;
    li {
      display: block;
      margin-bottom: 15px;
      a {
        text-decoration: none;
      }
    }
  }
`;
const EditNavLink = styled.div`
  ${props =>
    props.linkPath == props.urlPath &&
    `background: #FAF8FE; padding: 5px; border-left: 2px solid #3F2064; color: #3f2064;`}
`;
const EditDisplay = styled.div`
  display: inline-block;
  width: calc(100% - 250px);
  padding: 0 25px;
`;

function EditCaseContainer(props) {
  const [caseId, setCaseId] = useState(props.match.params.caseId);
  const [casePk, setCasePk] = useState();
  const [user, setUser] = useState();

  const [caseType, setCaseType] = useState();
  const [caseTitle, setCaseTitle] = useState();
  const [caseAuthor, setCaseAuthor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState();
  const [urlPath, setUrlPath] = useState();

  useEffect(() => {
    axios
      .post(`${url}/api/v0.1/fetch-case/`, {
        caseId: caseId
      })
      .then(response => {
          console.log(response.data.data)
        if (response.data.data) {
          const {
            hashid,
            caseTitle,
            user,
            caseAuthor,
            caseType,
            caseId
          } = response.data.data;
          setCaseType(caseType);
          setResponseData(response.data.data);
          setUser(user);
          setCaseAuthor(caseAuthor);
          setCaseTitle(caseTitle);
          setCasePk(caseId);
          setLoading(false);
        }
      });

    axios.post(`${url}/api/v0.1/fetch-user/`).then(response => {
      if (response.data.data) {
        console.log(response.data.data);
      }
    });

    return () => console.log("unmounting...");
  }, []);

  function handlePublishState(publishState) {
    axios
      .post(`${url}/api/v0.1/handle-case-publish-state/`, {
        caseId: casePk,
        publishState: publishState
      })
      .then(response => {
        setResponseData(prevState => {
          return { ...prevState, caseIsDraft: response.data.publishState };
        });
      });
  }
  function handlePrivateState(privateState) {
    axios
      .post(`${url}/api/v0.1/handle-case-private-state/`, {
        caseId: casePk,
        privateState: privateState
      })
      .then(response => {
        setResponseData(prevState => {
          return { ...prevState, caseIsPrivate: response.data.privateState };
        });
        console.log(response)
      });
  }

  function handleChangeSettings(content, type) {
    if (type == "updateThumbnail") {
      console.log("updating thumbnail");
      console.log(content);
      setResponseData(prevState => {
        return { ...prevState, caseThumbnail: content };
      });
    } else if (type == "deleteThumbnail") {
      setResponseData(prevState => {
        return { ...prevState, caseThumbnail: null };
      });
    } else if (type == "hashtags") {
      setResponseData(prevState => {
        return { ...prevState, caseHashtags: content };
      });
    } else if (type == "category") {
      setResponseData(prevState => {
        return { ...prevState, caseCategory: content };
      });
    }
  }

  function handleChangeTitle(title) {
    setResponseData(prevState => {
      return { ...prevState, caseTitle: title };
    });
  }

  function handleChangeGlobal(contentState, contentType, idData) {
    if (contentType === "question") {
      const newQuestion = responseData.questions.map(questionObject => {
        if (questionObject.id != idData) {
          return questionObject;
        } else {
          return {
            ...questionObject,
            question: contentState
          };
        }
      });
      setResponseData(prevState => {
        return { ...prevState, questions: newQuestion };
      });
    } else if (contentType === "explanation") {
      const newExplanation = responseData.explanation.map(explanationObject => {
        if (explanationObject.id != idData) {
          return explanationObject;
        } else {
          return {
            ...explanationObject,
            explanation: contentState
          };
        }
      });
      setResponseData(prevState => {
        return { ...prevState, explanation: newExplanation };
      });
    } else if (contentType === "stem") {
      const newStems = responseData.stems.map(stemObject => {
        if (stemObject.id != idData) {
          return stemObject;
        } else {
          return {
            ...stemObject,
            stem: contentState
          };
        }
      });
      setResponseData(prevState => {
        return { ...prevState, stems: newStems };
      });
    } else if (contentType === "choices") {
      const newQuestion = responseData.questions.map(questionObject => {
        if (questionObject.id != idData) return questionObject;
        return {
          ...questionObject,
          choices: contentState
        };
      });
      setResponseData(prevState => {
        return { ...prevState, questions: newQuestion };
      });
    }
  }

  function getUrlPath(url) {
    setUrlPath(url);
  }

  if (!responseData) {
    return <CaseContainer />;
  } else {
    return (
      <Fragment>
        <Header label={"Edit Case"} />
        <CaseContainer>
          <EditNav>
            <ul>
              <li>
                <EditNavLink linkPath={"/edit/:caseId"} urlPath={urlPath}>
                  <Link to={"/edit/" + caseId}>Edit Case</Link>
                </EditNavLink>
              </li>
              {responseData.caseIsDraft && (
                <Fragment>
                  <li>
                    <EditNavLink
                      linkPath={"/edit/:caseId/settings"}
                      urlPath={urlPath}
                    >
                      <Link to={"/edit/" + caseId + "/settings/"}>
                        Tags & Metadata
                      </Link>
                    </EditNavLink>
                  </li>
                  <li>
                    <EditNavLink
                      linkPath={"/edit/:caseId/preview"}
                      urlPath={urlPath}
                    >
                      <Link to={"/edit/" + caseId + "/preview/"}>Preview</Link>
                    </EditNavLink>
                  </li>
                  <li>
                    <EditNavLink
                      linkPath={"/edit/:caseId/publish"}
                      urlPath={urlPath}
                    >
                      <Link to={"/edit/" + caseId + "/publish/"}>Publish</Link>
                    </EditNavLink>
                  </li>
                </Fragment>
              )}
            </ul>
          </EditNav>

          <EditDisplay>
            <Route
              exact
              path="/edit/:caseId"
              render={props =>
                responseData.caseIsDraft ? (
                  <EditCase
                    {...props}
                    responseData={responseData}
                    getUrlPath={getUrlPath}
                    handleChangeTitle={handleChangeTitle}
                    handleChangeGlobal={handleChangeGlobal}
                  />
                ) : (
                  <EditPublishState
                    {...props}
                    responseData={responseData}
                    getUrlPath={getUrlPath}
                    handlePublishState={handlePublishState}
                    handlePrivateState={handlePrivateState}
                  />
                )
              }
            />
            <Route
              path="/edit/:caseId/settings"
              render={props =>
                responseData.caseIsDraft ? (
                  <EditCaseSettings
                    {...props}
                    responseData={responseData}
                    getUrlPath={getUrlPath}
                    handleChangeSettings={handleChangeSettings}
                    handlePrivateState={handlePrivateState}
                  />
                ) : (
                  <EditPublishState
                    {...props}
                    responseData={responseData}
                    getUrlPath={getUrlPath}
                    handlePublishState={handlePublishState}
                    handlePrivateState={handlePrivateState}
                  />
                )
              }
            />
            <Route
              path="/edit/:caseId/preview"
              render={props => (
                <CaseDetails
                  {...props}
                  responseData={responseData}
                  getUrlPath={getUrlPath}
                />
              )}
            />
            <Route
              path="/edit/:caseId/publish"
              render={props => (
                <EditPublishState
                  {...props}
                  responseData={responseData}
                  getUrlPath={getUrlPath}
                  handlePublishState={handlePublishState}
                  handlePrivateState={handlePrivateState}
                />
              )}
            />
          </EditDisplay>
        </CaseContainer>
      </Fragment>
    );
  }
}

export default EditCaseContainer;
